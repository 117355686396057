.form-date + .suffix,
.form-date + span + .suffix,
.form-date + .required + .suffix {
  cursor: pointer;
  pointer-events: none;
}
.date-picker {
  display: flex;
  flex: 1;
  height: 100%;
}
.date-picker .react-datepicker-wrapper,
.date-picker .react-datepicker__input-container {
  flex: 1;
  display: flex;
}
#date-picker .react-datepicker-popper {
  z-index: 9999;
}
#date-picker .react-datepicker {
  background: white;
  border: 1px solid #f4f4f4 !important;
  box-shadow: 0 5px 20px 0 rgba(13, 27, 42, 0.2);
  border-radius: 3px;
  padding: 16px;
}
#date-picker .react-datepicker .react-datepicker__header--custom {
  position: relative;
  font-weight: 400;
  font-size: 15px;
  color: #6b7897;
  letter-spacing: 0.5px;
  appearance: none;
  margin: 0 5px;
  cursor: pointer;
  line-height: 25px;
}
#date-picker .react-datepicker .react-datepicker__day--disabled {
  color: lightgray !important;
}
#date-picker .react-datepicker .react-datepicker__day--disabled:hover {
  background: none !important;
}
#date-picker .react-datepicker .react-datepicker__triangle {
  border-bottom-color: white;
}
#date-picker .react-datepicker .react-datepicker__current-month {
  text-align-last: left;
  padding: 0 13px;
}
#date-picker .react-datepicker .react-datepicker__month {
  margin: 0;
}
#date-picker .react-datepicker .react-datepicker__header {
  text-align-last: left;
  background: white;
  border: 0;
}
#date-picker .react-datepicker .react-datepicker__header__dropdown {
  display: inline;
  font-weight: 400;
  font-size: 15px;
  color: #6b7897;
  letter-spacing: 0.5px;
  padding: 0;
}
#date-picker .react-datepicker .react-datepicker__current-month {
  display: inline;
  font-weight: 400;
  font-size: 15px;
  color: #6b7897;
  letter-spacing: 0.5px;
  padding: 0;
}
#date-picker .react-datepicker .react-datepicker__year-read-view {
  display: inline;
  font-weight: 400;
  font-size: 15px;
  color: #6b7897;
  letter-spacing: 0.5px;
}
#date-picker .react-datepicker .react-datepicker__day-name {
  user-select: none;
  font-weight: 500;
  font-size: 15px;
  color: rgba(52, 72, 94, 0.54);
  text-align: center;
  line-height: 19px;
  width: 40px;
  height: 40px;
  line-height: 40px;
}
#date-picker .react-datepicker .react-datepicker__day {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-weight: 400;
  font-size: 14px;
  color: #7c86a2;
  text-align: center;
}
#date-picker .react-datepicker .react-datepicker__day:hover {
  background: rgba(51, 51, 255, 0.2);
  border-radius: 100%;
}
#date-picker .react-datepicker .react-datepicker__day.react-datepicker__day--outside-month {
  font-weight: 400;
  font-size: 14px;
  color: #f4f4f4;
  text-align: center;
}

#date-picker
  .react-datepicker
  .react-datepicker__month:not(.react-datepicker__month--selecting-range)
  .react-datepicker__day.react-datepicker__day--keyboard-selected,
#date-picker
  .react-datepicker
  .react-datepicker__month:not(.react-datepicker__month--selecting-range)
  .react-datepicker__day.react-datepicker__day--selected,
#date-picker .react-datepicker .react-datepicker__day:hover {
  background: #3333ff;
  border-radius: 100%;
  color: white;
}
.libeo-rebranding #date-picker
  .react-datepicker
  .react-datepicker__month:not(.react-datepicker__month--selecting-range)
  .react-datepicker__day.react-datepicker__day--keyboard-selected,
.libeo-rebranding  #date-picker
  .react-datepicker
  .react-datepicker__month:not(.react-datepicker__month--selecting-range)
  .react-datepicker__day.react-datepicker__day--selected,
.libeo-rebranding  #date-picker .react-datepicker .react-datepicker__day:hover {
  background: #005C3D;
}
.libeo-rebranding .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: #D9E6E2;
}
.libeo-rebranding .react-datepicker__day--in-selecting-range,
.libeo-rebranding .react-datepicker__month-text--in-selecting-range,
.libeo-rebranding .react-datepicker__quarter-text--in-selecting-range,
.libeo-rebranding .react-datepicker__year-text--in-selecting-range,
.libeo-rebranding .react-datepicker__day--selected,
.libeo-rebranding .react-datepicker__day--in-selecting-range,
.libeo-rebranding .react-datepicker__day--in-range,
.libeo-rebranding .react-datepicker__month-text--selected,
.libeo-rebranding .react-datepicker__month-text--in-selecting-range,
.libeo-rebranding .react-datepicker__month-text--in-range,
.libeo-rebranding .react-datepicker__quarter-text--selected,
.libeo-rebranding .react-datepicker__quarter-text--in-selecting-range,
.libeo-rebranding .react-datepicker__quarter-text--in-range,
.libeo-rebranding .react-datepicker__year-text--selected,
.libeo-rebranding .react-datepicker__year-text--in-selecting-range,
.libeo-rebranding .react-datepicker__year-text--in-range {
  background-color: #D9E6E2;
}
#date-picker
  .react-datepicker
  .react-datepicker__month.react-datepicker__month--selecting-range
  .react-datepicker__day.react-datepicker__day--keyboard-selected,
#date-picker
  .react-datepicker
  .react-datepicker__month.react-datepicker__month--selecting-range
  .react-datepicker__day.react-datepicker__day--selected {
  background: transparent;
  border-radius: 100%;
}
#date-picker .react-datepicker .form-select {
  position: relative;
  font-weight: 400;
  font-size: 15px;
  color: #6b7897;
  letter-spacing: 0.5px;
  appearance: none;
  margin: 0 5px;
  cursor: pointer;
  line-height: 25px;
}
#date-picker .react-datepicker .form-select select {
  max-height: 100px;
  border: 0;
  background: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 25px;
}
#date-picker .react-datepicker .form-select:after {
  content: " ";
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSI2cHgiIHZpZXdCb3g9IjAgMCAxMCA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Mi41ICg2NzQ2OSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBkPSJNMTAsMTIuNTEzMDQzNSBDOS44MzczOTEzLDEyLjUxMzA0MzUgOS42ODk1NjUyMiwxMi40NTM5MTMxIDkuNTcxMzA0MzUsMTIuMzM1NjUyMiBMNS44NzU2NTIxNSw4LjQ5MjE3MzkgQzUuNjUzOTEzMDIsOC4yNTU2NTIxNiA1LjY1MzkxMzAyLDcuODg2MDg2OTQgNS44OTA0MzQ3Niw3LjY0OTU2NTIgQzYuMTI2OTU2NSw3LjQyNzgyNjA3IDYuNDk2NTIxNzIsNy40Mjc4MjYwNyA2LjczMzA0MzQ2LDcuNjY0MzQ3ODEgTDEwLDExLjA2NDM0NzggTDEzLjI2Njk1NjUsNy42NjQzNDc4MSBDMTMuNDg4Njk1Nyw3LjQyNzgyNjA3IDEzLjg3MzA0MzUsNy40Mjc4MjYwNyAxNC4xMDk1NjUyLDcuNjQ5NTY1MiBDMTQuMzQ2MDg3LDcuODcxMzA0MzQgMTQuMzQ2MDg3LDguMjU1NjUyMTYgMTQuMTI0MzQ3OSw4LjQ5MjE3MzkgTDEwLjQyODY5NTcsMTIuMzM1NjUyMiBDMTAuMzEwNDM0OCwxMi40NTM5MTMxIDEwLjE2MjYwODcsMTIuNTEzMDQzNSAxMCwxMi41MTMwNDM1IFoiIGlkPSJjaGV2cm9uLWRvd24iPjwvcGF0aD4KICAgIDwvZGVmcz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iTW9sZWN1bGUvSEVBREVSIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTMwNi4wMDAwMDAsIC0yNS4wMDAwMDApIj4KICAgICAgICAgICAgPGcgaWQ9IkFycm93cy9jaGV2cm9uLWRvd24iPgogICAgICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMwMS4wMDAwMDAsIDE4LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxtYXNrIGlkPSJtYXNrLWNoZXZyb24tZG93bi0yIiBmaWxsPSJ3aGl0ZSI+CiAgICAgICAgICAgICAgICAgICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI2NoZXZyb24tZG93biI+PC91c2U+CiAgICAgICAgICAgICAgICAgICAgPC9tYXNrPgogICAgICAgICAgICAgICAgICAgIDx1c2UgaWQ9ImNoZXZyb24tZG93biIgZmlsbD0iIzAwNTNGQSIgZmlsbC1ydWxlPSJub256ZXJvIiB4bGluazpocmVmPSIjY2hldnJvbi1kb3duIj48L3VzZT4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==");
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0.75em;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  width: 10px;
  height: 9px;
  background-repeat: no-repeat;
}
#date-picker .react-datepicker .form-date-prev-month {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#date-picker .react-datepicker .form-date-next-month {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(-90deg);
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#date-picker .react-datepicker .react-datepicker__triangle:before {
  display: none;
}
#date-picker .react-datepicker .react-datepicker__navigation--next {
  position: absolute;
  right: 30px;
  top: 30px;
}
#date-picker .react-datepicker .react-datepicker__navigation--previous {
  position: absolute;
  right: 60px;
  top: 30px;
  left: inherit;
}
